<template>
  <div>
    <v-card width="1000" class="mx-auto">
      <v-card-title>
        <span class="headline">
          <b>{{ this.editProject ? "تعديل مشروع" : "تعديل جهة خارجية" }}</b>
        </span>
      </v-card-title>
      <v-divider></v-divider>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs12 md6>
                <v-text-field
                  label="الاسم"
                  placeholder="الاسم"
                  v-model="external.name"
                  :rules="externalnameRules"
                  required
                ></v-text-field>
              </v-flex>
              <!--    <v-flex xs12 md6>
                          <v-text-field
                                label="نوع الجهة الخارجية"
                                placeholder="نوع الجهة الخارجية"
                                v-model="externalsType"
                                readonly
                            ></v-text-field>   
                        </v-flex>
                       <v-flex xs12 md6>
                            <v-text-field
                                label="نوع الاسمنت المستخدم"
                                placeholder="نوع الاسمنت المستخدم"
                                v-model="external.cementPackage"
                                :rules="cementPackageRules"
                                required
                            ></v-text-field>
                        </v-flex>-->
              <v-flex xs12 md6>
                <v-text-field
                  label="المالك"
                  placeholder="المالك"
                  v-model="external.owner"
                  :rules="ownerRules"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  label="الموقع"
                  placeholder="الموقع"
                  v-model="external.location"
                  :rules="locationRules"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  label="الموبايل"
                  placeholder="00966011XXXXXXX"
                  v-model="external.mobile"
                  :rules="mobileRules"
                  type="number"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  label="الإيميل"
                  placeholder="الإيميل"
                  v-model="external.email"
                  :rules="emailRules"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-autocomplete
                 v-model="external.activity"
                
                  :items="ActivityList"
                  color="white"
                  item-text="name"
                  item-value="id"
                 :rules="activityTypeRules"
                  label="نوع النشاط"
              
                  required
                ></v-autocomplete>
            
                
              </v-flex>
              <!--     <v-flex xs12 md6>
                         <template>
                          <v-row>
                            <v-col
                              cols="12"
                              sm="12"
                              md="12"
                            >
                              <v-dialog
                                ref="dialog"
                                v-model="modal"
                                :return-value.sync="date"
                                persistent
                                width="290px"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="external.lastVisit"
                                    label="اخر زيارة"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="external.lastVisit"
                                  scrollable
                                  readonly
                                >
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    text
                                    color="primary"
                                    @click="modal = false"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.dialog.save(date)"
                                  >
                                    OK
                                  </v-btn>
                                </v-date-picker>
                              </v-dialog>
                            </v-col>
                          </v-row>
                        </template>
                        </v-flex> -->
              <v-flex xs12 md6>
                <v-autocomplete
                  v-model="transporterId"
                  :filter="customFilter"
                  :items="transporters"
                  color="white"
                  item-text="item"
                  item-value="value"
                  :rules="transportersRules"
                  label="الناقل"
                  return-object
                  required
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 md6 v-if="editProject">
                <v-autocomplete
                  v-model="mainContractor"
                  :filter="customFilter2"
                  :items="mainContractors"
                  color="white"
                  item-text="item"
                  item-value="value"
                  :rules="[(v) => !!v || 'المقاول الرئيسي مطلوب']"
                  label="المقاول الرئيسي"
                  return-object
                  required
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 md6 v-if="editProject">
                <v-autocomplete
                  v-model="secondaryContractor"
                  :filter="customFilter3"
                  :items="secondaryContractors"
                  color="white"
                  item-text="item"
                  item-value="value"
                  label="المقاول الثانوي"
                  :rules="[(v) => !!v || 'المقاول الثانوي مطلوب']"
                  return-object
                  required
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 md6 v-if="editProject">
                <v-text-field
                  label="المساحة"
                  placeholder="المساحة"
                  v-model="external.space"
                  :rules="[(v) => !!v || 'المساحة مطلوبة']"
                  required
                  type="number"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6 v-if="editProject">
                <v-text-field
                  label="التكلفة"
                  type="number"
                  placeholder="التكلفة"
                  v-model="external.cost"
                  :rules="[(v) => !!v || 'التكلفة مطلوبة']"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6 v-if="editProject">
                <v-text-field
                  label="المكتب الاستشاري"
                  placeholder="المكتب الاستشاري"
                  v-model="external.consultationOffice"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6 v-if="editProject">
                <v-text-field
                  label="المكتب الهندسي"
                  placeholder="المكتب الهندسي"
                  v-model="external.engineeringOofice"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6 v-if="editProject">
                <v-text-field
                  label="حالة المشروع"
                  placeholder="حالة المشروع"
                  v-model="external.projectStatus"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6 v-if="editProject">
                <v-text-field
                  label="الملاحظات"
                  placeholder="الملاحظات"
                  v-model="external.details"
                ></v-text-field>
              </v-flex>
              <!--     <v-flex xs12 md12 >
                            <GmapMap
                              :center="center"
                              :zoom="8"
                              map-style-id="roadmap"
                              :options="mapOptions"
                              style="width: 150vmin; height: 70vmin;"
                              ref="mapRef"
                              @click="handleMapClick"
                            >
                              <GmapMarker
                                :position="marker.position"
                                :clickable="true"
                                :draggable="true"
                                @drag="handleMarkerDrag"
                                @click="panToMarker"
                              />
                            </GmapMap>
                            <button @click="geolocate">Detect Location</button>
                            <p>Selected Position: {{ marker.position }}</p>
                           </v-flex>-->
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="saveExternal()"
            style="background: #5867dd; color: white; margin-left: 5px"
            dark
          >
            <b>حفظ</b>
          </v-btn>
          <v-btn @click="$router.go(-1)" style="background: gray" dark>
            <b>رجوع</b>
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      marker: { position: { lat: 24.4, lng: 46.71667 } },
      center: { lat: 24.4, lng: 46.71667 },
      mapOptions: {
        disableDefaultUI: false,
      },
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal: false,
      valid: true,
      transportersRules: [
        (v) => !!v || "الناقل مطلوب",
        (v) => v.value != "0" || "الاختيار غير مقبول",
      ],
      externalnameRules: [
        (v) => !!v || "الاسم مطلوب",
        (v) => (v && v.length >= 3) || "الاسم لا يجب أن يكون أقل من ثلاث محارف",
      ],
      emailRules: [
        (v) => !!v || "الإيميل مطلوب",
        (v) => /.+@.+\..+/.test(v) || "أدخل إيميل صحيح",
      ],
      locationRules: [(v) => !!v || "الموقع مطلوب"],
      secondaryContractors: [{ item: "اختر المقاول الثانوي", value: "0" }],
      mainContractors: [{ item: "اختر المقاول الرئيسي", value: "0" }],
      ownerRules: [
        (v) => !!v || "الاسم مطلوب",
        (v) => (v && v.length >= 3) || "الاسم لا يجب أن يكون أقل من ثلاث محارف",
      ],
      mobileRules: [
        (v) => !!v || "رقم الموبايل مطلوب",
        (v) =>
          (v && (v.length == 9 || v.length == 10 || v.length == 14)) ||
          "رقم الموبايل يجب أن يكون بطول 9 أو 10 أو 14 أرقام",
      ],
      activityTypeRules: [(v) => !!v || "نوع النشاط مطلوب"],
      selectedExternalType: 0,
      transporters: [{ item: "اختر ناقل", value: "0" }],
      transporterId: "",
      editProject: false,
      external: {
        name: "",
        cementPackage: "",
        owner: "",
        location: "",
        mobile: "",
        email: "",
        activity:'',
        activityType: "",
        lastVisit: new Date().toISOString().substr(0, 10),
        transportId: "",
        mainContractor: "",
        secondaryContractor: "",
        space: 0,
        cost: 0,
        consultationOffice: "",
        engineeringOofice: "",
        projectStatus: "",
        details: "",
      },
      mainContractor: "",
      secondaryContractor: "",
         ActivityList:[]
    };
  },
  methods: {
      async getListByActivity() {
      const response = await this.ApiService.get("Activity/List");

      response.data.responseData.forEach((el) => {
        this.ActivityList.push({ name: el.name, id: el.id });
      });
    },
    saveExternal() {
      if (this.$refs.form.validate()) {
        let data = null;
        let url = null;
        if (!this.editProject) {
          url = "External/updatexternal?id=";
          data = {
            name: this.external.name,
            cementPackage: this.external.cementPackage,
            owner: this.external.owner,
            location: this.external.location,
            mobile: this.external.mobile,
            email: this.external.email,
              activityId: this.external.activity,
            activityType: this.external.activityType,
            lastVisit: this.external.lastVisit,
            transporterId: this.transporterId.value,
          };
        } else {
          url = "external/updateproject?id=";
          data = {
            mainContractor: this.mainContractor.value,
            secondaryContractor: this.secondaryContractor.value,
            space: this.external.space,
            cost: this.external.cost,
            cementPackage: this.external.cementPackage,
            consultationOffice: this.external.consultationOffice,
            engineeringOofice: this.external.engineeringOofice,
            projectStatus: this.external.projectStatus,
            details: this.external.details,
            externalId: this.external.idproject,
            name: this.external.name,
            owner: this.external.owner,
            location: this.external.location,
            mobile: this.external.mobile,
            email: this.external.email,
               activityId: this.external.activity,
            activityType: this.external.activityType,
            lastVisit: this.external.lastVisit,
            transporterId: this.transporterId.value,
          };
        }
        this.ApiService.put(url + this.$route.params.externalId, data)
          .then(() => {
            this.hel.showSnackMsg("تمت العملية بنجاح", "#58dd5b", 2000);
            this.$router.push({ name: "externals" });
          })
          .catch(() => {
            this.hel.showSnackMsg("فشلت العملية", "#af1616", 2000);
          });
      }
    },
    async getContractors() {
      this.secondaryContractors = [
        { item: "اختر المقاول الثانوي", value: "0" },
      ];
      this.mainContractors = [{ item: "اختر المقاول الرئيسي", value: "0" }];
      await this.ApiService.get("external/getallexternals?id=3")
        .then((res) => {
          var allContractors = res.data.responseData;
          allContractors.forEach((el) => {
            if (el.idexternal == this.external.mainContractor) {
              this.mainContractor = { item: el.name, value: el.idexternal };
            }
            if (el.idexternal == this.external.secondaryContractor) {
              this.secondaryContractor = {
                item: el.name,
                value: el.idexternal,
              };
            }
            this.mainContractors.push({ item: el.name, value: el.idexternal });
            this.secondaryContractors.push({
              item: el.name,
              value: el.idexternal,
            });
          });
        })
        .catch(() => {});
    },
    async getTransporters() {
      (this.transporters = [{ item: "اختر ناقل", value: "0" }]),
        this.ApiService.get("Transporters/getalltransporters")
          .then((res) => {
            var allTransporters = res.data.responseData;
            allTransporters.forEach((el) => {
              this.transporters.push({
                value: el.idtransporter,
                item: el.name,
              });
              if (el.idtransporter == this.external.transporterId) {
                this.transporterId = { item: el.name, value: el.idtransporter };
              }
            });
          })
          .catch(() => {});
    },
    async getExternal(id) {
      let url = "External/getexternal?id=";
      if (this.editProject) {
        url = "external/getproject?id=";
      }
      await this.ApiService.get(url + id)
        .then((res) => {
          this.external = res.data.responseData[0];
          const formatYmd = (date) => date.toISOString().slice(0, 10);
          this.external.lastVisit = formatYmd(
            new Date(this.external.lastVisit)
          );
          if (this.editProject) {
            this.getContractors();
          }
          this.getTransporters();
        })
        .catch(() => {});
    },
    customFilter(item, queryText) {
      const textOne = item.item.toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },
    customFilter2(item, queryText) {
      const textOne = item.item.toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },
    customFilter3(item, queryText) {
      const textOne = item.item.toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },
  },
  created() {
    this.editProject = this.$route.name == "edit-project" ? true : false;
    this.getExternal(this.$route.params.externalId);
        this.getListByActivity()
  },
};
</script>
<style lang="scss"></style>
